import React, { useEffect, useState } from "react";
import {
  Row,
  Card,
  CardBody,
  Input,
  Button,
  Form,
  FormGroup,
  Label,
  Col,
} from "reactstrap";
import { Colxx, Separator } from "components/common/CustomBootstrap";
import { toast } from "react-toastify";

import { useToken } from "auth/useToken";
import { useUser } from "auth/useUser";
import axios from "axiosClient";
import DatePicker from "react-datepicker";
import axiosClient from "axiosClient";

const AddWindowModal = (props) => {
  const { toggle, values, setValues, slotId } = props;
  const user = useUser();

  const [thisValues, setThisValues] = useState({});
  const [loading, setLoading] = useState(false);
  const [token, setToken] = useToken();

  const [success, setSuccess] = useState(false);
  const [updated, setUpdated] = useState(false);
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const handleChange = (e) => {
    setUpdated(true);
    const { name, value } = e.target;
    setThisValues({
      ...thisValues,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    // console.log("start", startDate.getHours(), startDate.getMinutes());
    // console.log("end", endDate.getHours(), endDate.getMinutes());
    try {
      let response = await axiosClient.post(
        "/api/speaking/add-new-window",
        {
          time: `${startDate.getHours()}:${
            startDate.getMinutes() === 0 ? "00" : startDate.getMinutes()
          } - ${endDate.getHours()}:${
            endDate.getMinutes() === 0 ? "00" : endDate.getMinutes()
          }`,
          slotId,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );
      let tempValues = [...values, response.data.item];
      setValues(tempValues);
      toast.success("New slot has been added.");
    } catch (err) {
      toast.error(JSON.stringify(err.data) || "Server error");
    }
    setLoading(false);
  };

  return (
    <div>
      <Row>
        <Colxx xxs="12">
          <div className="mb-2">
            <h1>Add Slot</h1>
          </div>
          <Separator className="mb-5" />
        </Colxx>
      </Row>
      {!loading && (
        <Row>
          <Colxx xxs="12" className="mb-4">
            <Card className="mb-4">
              <CardBody>
                <Form>
                  <Row>
                    <Col md="3">
                      <FormGroup>
                        <Label>Start</Label>
                        <DatePicker
                          selected={startDate}
                          onChange={(date) => setStartDate(date)}
                          showTimeSelect
                          showTimeSelectOnly
                          timeIntervals={20}
                          timeCaption="Start Time"
                          dateFormat="hh:mm"
                        />
                      </FormGroup>
                    </Col>
                    <Col md="3">
                      <FormGroup>
                        <Label>End</Label>
                        <DatePicker
                          selected={endDate}
                          onChange={(date) => setEndDate(date)}
                          showTimeSelect
                          showTimeSelectOnly
                          timeIntervals={20}
                          timeCaption="End Time"
                          dateFormat="hh:mm"
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                </Form>

                <div className="mt-3">
                  <Button
                    color="light"
                    className="top-right-button"
                    onClick={toggle}
                  >
                    Close
                  </Button>
                  <Button
                    color={success ? "success" : "primary"}
                    className="top-right-button ml-2"
                    onClick={handleSubmit}
                    disabled={success}
                  >
                    {success ? "Done" : `Add`}
                  </Button>
                </div>
              </CardBody>
            </Card>
          </Colxx>
        </Row>
      )}
    </div>
  );
};

export default AddWindowModal;
